// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var CurrentUser__Context = require("./CurrentUser__Context.bs.js");

function isEnabledHelper(currentUser, feature) {
  return Core__Option.isSome(Core__List.getBy(currentUser.plan.features, (function (enabledFeature) {
                    return enabledFeature === feature;
                  })));
}

function isCurrentUser(otherAgentId, currentUserContext) {
  var match = currentUserContext.user;
  if (otherAgentId !== undefined && match !== undefined) {
    return otherAgentId === match.id;
  } else {
    return false;
  }
}

function getChapterPreferences(contextValue) {
  return Core__Option.flatMap(contextValue.chapter, (function (chapter) {
                return chapter.preferences;
              }));
}

function shouldShowPropertyDetails(propertyAgentId, context) {
  if (isCurrentUser(propertyAgentId, context)) {
    return true;
  } else {
    return isEnabledHelper(context, "PROPERTY_DETAILS");
  }
}

function useCurrentUser(param) {
  var currentUser = React.useContext(CurrentUser__Context.context);
  if (typeof currentUser !== "object") {
    if (currentUser === "Unauthenticated") {
      return CurrentUser__Context.legacyUnauthenticatedValue;
    } else {
      return CurrentUser__Context.initialLegacyValue;
    }
  }
  if (currentUser.TAG !== "Agent") {
    return {
            userRole: "client",
            chapter: CurrentUser__Context.legacyUnauthenticatedValue.chapter,
            user: CurrentUser__Context.legacyUnauthenticatedValue.user,
            role: CurrentUser__Context.legacyUnauthenticatedValue.role,
            accountOwner: CurrentUser__Context.legacyUnauthenticatedValue.accountOwner,
            plan: CurrentUser__Context.legacyUnauthenticatedValue.plan,
            tanStaff: CurrentUser__Context.legacyUnauthenticatedValue.tanStaff,
            terminatedAccount: CurrentUser__Context.legacyUnauthenticatedValue.terminatedAccount,
            isLoggedIn: true,
            initialized: true,
            setUser: CurrentUser__Context.legacyUnauthenticatedValue.setUser,
            reload: CurrentUser__Context.legacyUnauthenticatedValue.reload
          };
  }
  var agent = currentUser._0;
  return {
          userRole: "agent",
          chapter: agent.chapter,
          user: agent.user,
          role: agent.role,
          accountOwner: agent.accountOwner,
          plan: agent.plan,
          tanStaff: agent.tanStaff,
          terminatedAccount: agent.terminatedAccount,
          isLoggedIn: true,
          initialized: true,
          setUser: agent.setUser,
          reload: agent.reload
        };
}

function useUserHasFeature(feature) {
  var user = useCurrentUser();
  return isEnabledHelper(user, feature);
}

function useShouldShowPropertyDetails(propertyAgentId, propertyAgent, param) {
  var user = useCurrentUser();
  var agentId = propertyAgentId !== undefined ? propertyAgentId : (
      propertyAgent !== undefined && typeof propertyAgent === "object" ? propertyAgent._0.id : undefined
    );
  return shouldShowPropertyDetails(agentId, user);
}

function useChapterPreferences(param) {
  var user = useCurrentUser();
  return [
          user.initialized,
          getChapterPreferences(user)
        ];
}

function agentIsCurrentUser(agentId) {
  var user = useCurrentUser();
  var user$1 = user.user;
  if (user$1 !== undefined) {
    return user$1.id === agentId;
  } else {
    return false;
  }
}

function useAgentIsCurrentUser(agentId, param) {
  var user = useCurrentUser();
  var match = user.user;
  if (match !== undefined && agentId !== undefined) {
    return match.id === agentId;
  } else {
    return false;
  }
}

function sameAccountAsCurrentUser(otherAgent, param) {
  var user = useCurrentUser();
  var match = user.user;
  if (match === undefined) {
    return false;
  }
  var match$1 = match.account;
  if (match$1 === undefined) {
    return false;
  }
  if (otherAgent === undefined) {
    return false;
  }
  var match$2 = otherAgent.account;
  if (match$2 !== undefined) {
    return match$1.id === match$2.id;
  } else {
    return false;
  }
}

function isOnTrial(param) {
  var user = useCurrentUser();
  return user.plan.handle === "PRO_TRIAL";
}

function useCurrentSession(param) {
  return React.useContext(CurrentUser__Context.context);
}

function useCurrentUserIsClientWithAutonotifyBuyerNeed(param) {
  var currentSession = React.useContext(CurrentUser__Context.context);
  if (typeof currentSession !== "object" || currentSession.TAG !== "Client") {
    return false;
  } else {
    return currentSession._0.hasAnAutonotifyBuyerNeed;
  }
}

function useIsImpersonated(param) {
  var currentSession = React.useContext(CurrentUser__Context.context);
  if (typeof currentSession !== "object") {
    return false;
  } else if (currentSession.TAG === "Agent") {
    return currentSession._0.isImpersonated;
  } else {
    return currentSession._0.isImpersonated;
  }
}

var Types;

var Provider;

exports.Types = Types;
exports.Provider = Provider;
exports.isEnabledHelper = isEnabledHelper;
exports.isCurrentUser = isCurrentUser;
exports.getChapterPreferences = getChapterPreferences;
exports.shouldShowPropertyDetails = shouldShowPropertyDetails;
exports.useCurrentUser = useCurrentUser;
exports.useUserHasFeature = useUserHasFeature;
exports.useShouldShowPropertyDetails = useShouldShowPropertyDetails;
exports.useChapterPreferences = useChapterPreferences;
exports.agentIsCurrentUser = agentIsCurrentUser;
exports.useAgentIsCurrentUser = useAgentIsCurrentUser;
exports.sameAccountAsCurrentUser = sameAccountAsCurrentUser;
exports.isOnTrial = isOnTrial;
exports.useCurrentSession = useCurrentSession;
exports.useCurrentUserIsClientWithAutonotifyBuyerNeed = useCurrentUserIsClientWithAutonotifyBuyerNeed;
exports.useIsImpersonated = useIsImpersonated;
/* react Not a pure module */
